import { getContrast } from 'color2k';

const DARK_COLOR_THRESHOLD = 4.5;
const BLACKISH_COLOR_THRESHOLD = 1.5;

const isDarkColor = (color?: string) => (color
  ? getContrast(color, '#fff') > DARK_COLOR_THRESHOLD
  : false);

const isBlackishColor = (color?: string) => (color
  ? getContrast(color, '#000') <= BLACKISH_COLOR_THRESHOLD
  : false);

export {
  isBlackishColor,
  isDarkColor,
};
